<template>
    <div v-if="checkIsLogin()" class="balance-and-exp-sec">
        <div class="balance-sec">
            <span> {{ translatedLangData('balance','Balance')}} : &nbsp; <strong class="balance">{{ getUserDetails?.balance }}</strong> {{ translatedLangData('pti','PTI')}}</span>
        </div>
        
        <div class="exp-sec">
            <span>{{ translatedLangData('exp','Exp')}} : &nbsp; <strong class="exp">{{ Math.abs(getUserDetails?.exposure) }}</strong></span>
            <a href="Javascript:void(0);" @click="getWalletGetway()" class="tiger-main-header-wallet" v-if="siteSettings?.business_type == 2">{{ translatedLangData('wallet','Wallet')}}</a>
            <!-- <router-link :to="{ name: 'wallet-deposit', query: { walletData: null } }">Deposit</router-link>
            <span>Exp : &nbsp; <strong>{{ getUserDetails.exposure }}</strong> </span> -->
            <a data-bs-toggle="offcanvas" href="#offcanvasmenu" role="button" aria-controls="offcanvasExample" class="tiger-main-header-account">{{ translatedLangData('account','Account')}}</a>
        </div>
    </div>

    <div class="nav-bar-toggle" v-if="this.$route.name == 'Inplay' || this.$route.name == 'Featured' || this.$route.name=='sports'  || this.$route.name == 'SportsEventDetail' || this.$route.name == 'SportsEventDetailsports'">
        <div class="inply-main-logo">
            <!-- <router-link :to="{name:'Home'}"> -->
            <img @click="gotoHome()" :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo" alt="">
            <!-- </router-link> -->
        </div>
        
        <div class="side-bar-trigger">
            <a data-bs-toggle="offcanvas" href="#offcanvasmenu" role="button" aria-controls="offcanvasExample"><i class="fa-solid fa-bars"></i></a>
        </div>
        <a href="Javascript:void(0);" @click="getWalletGetway()" class="tiger-mobile-wallet" v-if="siteSettings?.business_type == 2"><img loading="lazy" src="@/assets/images/wallet.webp" alt=""></a>
    </div>
    <div class="nav-bar-toggle" v-else>
        <div class="inply-main-logo">
            <a>{{ headName() }}</a>
        </div>
        <div class="side-bar-trigger">
            <a data-bs-toggle="offcanvas" href="#offcanvasmenu" role="button" aria-controls="offcanvasExample"><i class="fa-solid fa-bars"></i></a>
        </div>
        <a href="Javascript:void(0);" @click="getWalletGetway()" class="tiger-mobile-wallet" v-if="siteSettings?.business_type == 2"><img loading="lazy" src="@/assets/images/wallet.webp" alt=""></a>
    </div>
    <div class="moving-text-sec" v-if="siteSettings && siteSettings.announcement && siteSettings.announcement.msg">
        <marquee :style="{ color: siteSettings?.announcement?.color_code }" behavior="scroll" direction="" scrollamount="10" onmouseover="this.stop();" onmouseout="this.start();">
           {{siteSettings.announcement.msg}}
        </marquee>
    </div>
    <div class="deposit-withdraw-btn" v-if="siteSettings?.business_type == 2">
        <div class="d-w-btns">
            <ul class="d-w-list">
                <li>
                    <a class="btn" href="Javascript:void(0);" @click="getWalletGetway()">Deposit</a>
                </li>
                <li>
                    <a class="btn withdraw-btn" href="Javascript:void(0);" @click="getWalletGetway()">Withdraw</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="tabs-fixed-width-sec">
        <div class="tabs-list">
            <ul v-if="this.$route.name=='Inplay' || this.$route.name=='sports' || this.$route.name=='Featured'">
                <li @click="set_tab('featured'), currentTab = 'featured'">
                    <router-link :to="{ name: 'Featured' }" :class="this.$route.name == 'Featured' ? 'active' : ''">
                        <img loading="lazy" src="@/assets/images/star-selected.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('featured','Featured')}}</span>
                        </div>
                        <div class="game-count">{{ FeaturedCount }}</div>
                    </router-link>
                </li>
                <!-- <li @click="set_tab('inplay'), currentTab = 'inplay'">
                    <router-link :to="{ name: 'Inplay' }" :class="this.$route.name == 'Inplay' ? 'active' : ''">
                        <img loading="lazy" src="@/assets/images/inplay-clock.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('inplay','In-Play')}}</span>
                        </div>
                        <div class="game-count">{{ inplayCount }}</div>
                    </router-link>
                </li> -->
                <li v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                    @click="set_tab(sport.id), currentTab = sport.id">
                    <router-link :to="{ name: 'sports', params: { type: sport.slug, id: sport.id } }"
                        :class="{ 'active': this.$route.params.id == sport.id }">
                        <img v-if="sport.is_custom===1" :src="clickApiUrl + sport.sport_icon" alt="">
                        <img v-else-if="sport.id == 4" src="@/assets/images/icon-cricket.webp" alt="">
                        <img v-else-if="sport.id == 1" src="@/assets/images/icon-football.webp" alt="">
                        <img v-else-if="sport.id == 2" src="@/assets/images/icon-tennis.webp" alt=""> 
                        <div class="tabs-text">
                            <span> {{translatedLangData( sport.name.toLowerCase() , sport.name) }} </span>
                        </div>
                        <div class="game-count">{{ total_matches?.[sport.id]?.total }}</div>
                    </router-link>
                </li>
                <!-- This below code is commented due to static data -->
                <!-- <li>

                    <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }"
                        @click="type = 'HORSE_RACING'" :class="{ 'active': type == 'HORSE_RACING' }">

                        <img loading="lazy" src="@/assets/images/icon-horse.webp" alt="">
                        <div class="tabs-text">
                            <span>Horse Racing</span>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACING' } }"
                        @click="type = 'GREY_HOUND_RACING'" :class="{ 'active': type == 'GREY_HOUND_RACING' }">
                        <img loading="lazy" src="@/assets/images/icon-greyhound.webp" alt="">
                        <div class="tabs-text text-truncate">
                            <span class="">Greyhound Racing </span>
                        </div>
                    </router-link>
                </li> -->
                <li v-if="checkEventManageforSingleType('sports-book')">
                    <router-link to="/cricket-fight">
                        <img loading="lazy" src="@/assets/images/cricket-bat.png" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('cricket-fight','Cricket Fight')}}</span>                           
                        </div>
                    </router-link>
                </li>
                <li v-if="checkEventManageforSingleType('sports-book')">
                    <router-link :to="{name:'SportsBook'}">
                        <img loading="lazy" src="@/assets/images/sportsbook-icon.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('sportsbook','SportsBook')}}</span>                           
                        </div>
                    </router-link>
                </li>
                <li v-if="checkEventManageforSingleType('worli-matka')">
                    <router-link to="/matka">
                        <img loading="lazy" src="@/assets/images/matka-icon.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('matka','Matka')}}</span>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/casino">
                        <img loading="lazy" src="@/assets/images/casino-icon.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('casino','Casino')}}</span>
                        </div>
                    </router-link>
                </li>

                <li>
                    <router-link to="/int-casino">
                        <img loading="lazy" src="@/assets/images/int-casino.webp" alt="">
                        <div class="tabs-text">
                            <span>{{ translatedLangData('int-casino','Int Casino')}}</span>
                        </div>
                    </router-link>
                </li>

                <li v-for="(c_sport, c_index) in custom_sports" :key="c_index"
                    @click="set_tab(c_sport.id), currentTab = c_sport.id">
                    <router-link :to="{name:'sports', params:{type: c_sport.slug,id:c_sport.id} }" :class="{ 'active': currentTab == c_sport.id }">
                        <!-- <img v-if="c_sport.is_custom===1" :src="clickApiUrl + c_sport.sport_icon" alt="">
                            <img v-else-if="c_sport.id == 648" src="@/assets/images/pro-kabaddi-icon.webp" alt=""> -->
                            <img v-if="c_sport.id == 648" src="@/assets/images/pro-kabaddi-icon.webp" alt=""> 
                            <img v-if="c_sport.id == 4342" src="@/assets/images/world-cup.png" alt=""> 
                            <img v-if="c_sport.id == 4341" src="@/assets/images/loksabha.png" alt=""> 
                            <img v-if="c_sport.id == 4340" src="@/assets/images/ipl.png" alt=""> 
                            <!-- <img  :src="clickApiUrl +c_sport.sport_icon" alt=""> -->
                        <div class="tabs-text">
                            <span>{{ translatedLangData(c_sport.slug.trim().toLowerCase(),c_sport.name)}} </span>                           
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

// import Inplay from '@/modules/sports/views/Inplay.vue';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";
import api from '../services/api';
import * as varConstants from '@/shared/constants/var-constants';
import * as apiName from '../services/urls';
export default {
    name: 'MainHeader',
    inject:['translatedLangData'],
    data() {
        return {
            isVisible: false,
            non_custom_sports: [],
            custom_sports: [],
            currentTab: 'inplay',
            matches: null,
            clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
            total_matches:null,
            eventManage: null
        }
    },
    computed:{
        siteSettings() {    
                console.log("check site setting",this.$store.getters.siteSettings)
				return this.$store.getters.siteSettings;
			},
        getUserDetails() {
            return this.$store?.getters?.stateUser
        },
        inplayCount(){
            var sum=0;
            for(var data in this.total_matches ){
                sum+=this.total_matches[data].inplay
            }
            return sum
        },
        FeaturedCount(){
            let recc_list = JSON.parse(localStorage.getItem(varConstants.RECC_SPORTS_LIST));
            if(recc_list){
                return recc_list.length;
            }
            return null;
        }
    },
    created() {
        this.get_sportsList();
        this.eventManage = this.$store?.getters?.eventManage;
    },
    mounted() {
        this.matches = JSON.parse(localStorage.getItem('Matches'))
        this.getTotalMatches()
    },
    methods: {
        gotoHome(){
            this.$router.push({name:'Home'})
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },

        headName() {
            let routeName = this.$route.name;
            if (routeName == 'AccountStatement') {
                let paramName = this.$route.params.type;
                switch (paramName) {
                    case 'account':
                        return this.translatedLangData('account-statement','Account Statement');
                    case 'profit-loss':
                        return this.translatedLangData('profit-loss','Profit-Loss');
                }
            } else {
                switch (routeName) {
                    case 'UnsttledBets':
                        return this.translatedLangData('unsettled-bets','Unsettled Bets')
                        // return 'Un-Settled Bets'
                    case 'wallet-deposit':
                        return this.translatedLangData('deposit','Deposit');
                    case 'Terms':
                        return this.translatedLangData('rules','Terms');
                    case 'wallet-withdraw':
                        return this.translatedLangData('withdraw','Withdraw');
                    case 'deposit-payment-method':
                        return 'Deposit Payment Method';
                    case 'bonus':
                        return this.translatedLangData('bonus','Bonus');
                    case 'refer-earn':
                        return this.translatedLangData('refer-and-earn','Refer and Earn');
                    case 'unsettled-bets':
                        return this.translatedLangData('unsettled-bets','Unsettled Bets');
                    case 'MultiMarket':
                        return this.translatedLangData('multi-market','Multi Markets');
                    case 'Profile':
                        return this.translatedLangData('profile','Profile');
                    case 'Rules':
                        return this.translatedLangData('rules','Rules');
                    case 'EditStakes':
                        return this.translatedLangData('edit-stake','Edit Stake');
                    case 'MainTerms':
                        return this.translatedLangData('terms-and-conditions','Terms and Conditions');
                }
            }
        },
        // betSlip() {
        //     if (!this.isVisible) {
        //         var bets = document.getElementById('bets-detail');
        //         console.log("chek",bets)
        //         if(bets.style.display==='none'|| bets.style.display===''){
        //             bets.style.display='block';
        //         }
        //         else {
        //             bets.style.display="none"
        //         }
        //         this.isVisible = true
        //     }
        //     // $(".bets-details-in-mobile").toggle();
        //     // $(".book-maker-section").css("display", "none");
        // },
        // marketShow() {
        //     if (this.isVisible) {
        //         var bets_details_in_mobile = document.getElementById('bets-detail')
        //         console.log("check element", bets_details_in_mobile)
        //         if (bets_details_in_mobile.style.display === 'none' ||
        //             bets_details_in_mobile.style.display === "") {
        //             bets_details_in_mobile.style.display = "block"
        //         }
        //         else {
        //             bets_details_in_mobile.style.display = "none"
        //         }
        //         this.isVisible = false
        //         // $(".bets-details-in-mobile").toggle();
        //         // this.isVisible = false
        //     }
        //     // $(".book-maker-section").css("display", "block");
        // },
        // showTv() {
        //     // $("#live-tv-pin").toggle();
        //     var liveTvPin = document.getElementById("live-tv-pin");
        //     if (
        //         liveTvPin.style.display === "none" ||
        //         liveTvPin.style.display === ""
        //     ) {
        //         liveTvPin.style.display = "block";
        //     } else {
        //         liveTvPin.style.display = "none";
        //     }
        // },
        checkEventManageforSports(sportId) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == 'sports');
                if(sportEventManage) {
                    let sportIds = sportEventManage.type_id.split(',');
                    return !sportIds.includes(sportId.toString());
                }
            }
            return true;    
        },
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin()) {
                let sportEventManage = this.eventManage?.find(item => item.type == type);
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;    
        },
        get_sportsList() {
            this.loading = true;
            api.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        var all_sports = response.data.data
                        this.non_custom_sports = []
                        for (var i = 0; i < all_sports.length; i++) {
                            if(this.checkEventManageforSports(all_sports[i].id)) {
                                if (all_sports[i].is_custom == 0) {
                                    this.non_custom_sports.push(all_sports[i])
                                } else {
                                    this.custom_sports.push(all_sports[i])
                                }
                            }
                           all_sports[i]?.name?.toLowerCase() === 'soccer' ? all_sports[i].name = 'Football' : '';
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list = {}
                        sport_list['custom'] = this.custom_sports
                        sport_list['non_custom'] = this.non_custom_sports
                        localStorage.setItem('sports_List', JSON.stringify(sport_list))
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    // this.showErrorModalFunc(error.data.message);
                }
            });
        },
        getTotalMatches(){
            this.loading=true;
            api.get(apiName.GET_TOTAL_MATCHES).then(response=>{
                this.loading=false;
                if(response){
                    if(response.status==200){
                        this.total_matches=response.data.data
                    }
                }
            })
        },
        set_tab(name) {
            this.$store.commit('set_sport_tab', name)
            // this.$router.push('/home')
        },
        getWalletGetway() {
            this.$router.push({name: 'wallet-gateway'});
            // getWalletGetwayServiceCall();
        }
    },
}
</script>

<style scoped>
.d-w-list{
    margin-left:-5px;
    margin-right:-5px;
    list-style: none;
}
.d-w-list li {
    padding-left: 5px;
    padding-right: 5px;
    width: 50%;
    float: left;
}
.d-w-list .btn{
    border-radius: 5px;
    padding: 4px 15px;
    color: #ffffff !important;
    background-color: #ffa143;
    width: 100%;
    margin: 0;
}
.deposit-withdraw-btn {
    width: 100%;
    padding: 5px;
}
.d-w-list .btn.withdraw-btn {
    background-color: #657e95;
}
</style>