<template>
    <div class="modal fade" id="myModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="background-filter">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <figure class="logo-pop-up">
                            <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="">
                        </figure>
                        <button ref="close_btn" type="button" class="btn-close"
                            data-bs-dismiss="modal" aria-label="Close">
                            <img loading="lazy" src="@/assets/images/close-btn.webp" alt="">
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="registration-form tig-login-form-wrapper">
                            <h2  class="register-title">{{ translatedLangData('login-cap','LOGIN')}}</h2>
                            <hr class="form-divider-line">
                            <!-- <div class="get-mob">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="col-auto">
                                            <select v-model="form.type" class="form-select" id="autoSizingSelect">
                                                <option value="1">Phone</option>
                                                <option value="2">ID</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div v-if="form.type == 1" class="col-auto">
                                            <input type="text" v-model="form.mobileNo" class="form-control add"
                                                id="autoSizingInput" placeholder="Enter Number">
                                        </div>

                                        <div v-if="form.type == 2" class="col-auto">
                                            <input type="text" v-model="form.userId" class="form-control add"
                                                id="autoSizingInput" placeholder="Enter ID">
                                        </div>
                                    </div>
                                    <div v-if="form.type == 1" class="phone-country demo col-2">
                                        <div class="mm-dropdown-con ">
                                            <select v-model="form.code" class="form-select textfirst-con "
                                                aria-label="Default select example">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="tab-login-sec">
                                <ul class="nav nav-tabs" id="myTab" role="tablist"  v-if="siteSettings?.business_type == 2">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" :class="form.type == 1 ? 'active': ''" id="phone-tab" data-bs-toggle="tab"
                                            data-bs-target="#phone" type="button" role="tab" aria-controls="phone"
                                            aria-selected="true" @click="form.type = 1,refreshData(1)">{{ translatedLangData('mobile-number','Mobile Number')}}</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" :class="form.type == 2 ? 'active': ''" id="user-tab" data-bs-toggle="tab" data-bs-target="#user"
                                            type="button" role="tab" aria-controls="user" aria-selected="false"
                                            @click="form.type = 2,refreshData(2)">{{ translatedLangData('user-id','User ID')}}</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent" v-if="siteSettings?.business_type == 2">
                                    
                                    <div class="tab-pane fade" :class="form.type == 1 ? 'show active': ''" id="phone" role="tabpanel" aria-labelledby="phone-tab">
                                        
                                        <label for="" class="form-cmn-title">{{ translatedLangData('mobile-number','Mobile Number')}}*</label>
                                            
                                            <div class="phone-inpt-sec">
                                                <div class="slt-country-code">
                                                    <select v-model="form.code" class="form-select textfirst-con " :disabled="demoLoading || loading"
                                                    aria-label="Default select example">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                    </select>
                                                </div>
                                       
                                                <div class="input-number-box">
                                                    <input type="text" @input="validateMobile($event)" minlength="10" maxlength="10" v-model="form.mobileNo" 
                                                    :disabled="demoLoading || loading"
                                                    class="form-control add" id="autoSizingInput" :placeholder="translatedLangData('enter-mobile-number','Enter Mobile Number')">
                                                </div>
                                            </div>
                                            
                                        </div>

                                    <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="user" role="tabpanel" aria-labelledby="user-tab">
                                        <label for="" class="form-cmn-title">{{ translatedLangData('user-id','User ID')}}*</label>
                                        <input type="text" v-model="form.userId" class="form-control add" id="autoSizingInput" @input="validateUserId" 
                                        :placeholder="translatedLangData('enter-user-id','Enter User ID')" :disabled="demoLoading || loading">
                                        <!-- <div class="inp-img"><img loading="lazy" src="@/assets/images/user-icon.webp" alt=""></div> -->
                                    </div>
                                </div>
                                <div class="tab-content" id="myTabContent" v-else>
                                    <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
                                        <label for="" class="form-cmn-title">{{ translatedLangData('user-id','User ID')}}*</label>
                                        <input type="text" v-model="form.userId" @input="validateUserId" class="form-control add" id="autoSizingInput" 
                                        :placeholder="translatedLangData('enter-user-id','Enter User ID')" :disabled="demoLoading || loading">
                                        <!-- <div class="inp-img"><img loading="lazy" src="@/assets/images/user-icon.webp" alt=""></div> -->
                                    </div>
                                </div>
                            </div>

                            <div v-if="showValidationError && getId() == ''"
                                class="thm-heading justify-content-start mt-2  password-wrong-con">
                                <p class="wrong-color">{{translatedLangData('please-enter','Please Enter')}} {{ form.type == 1 ? translatedLangData('mobile-number','Mobile Number') : translatedLangData('user-id','User ID') }}
                                </p>
                            </div>

                            <div class="get-mob for">
                                <label for="" class="form-cmn-title">{{translatedLangData('password','Password')}}*</label>
                                <input :type="passwordFieldType" v-model="form.password" @input="validatePassword" :disabled="demoLoading || loading"
                                class="form-control" id="inputPassword2" :placeholder="translatedLangData('enter-pass','Enter Password')">
                                <div class="inp-img"><img loading="lazy" src="@/assets/images/key.webp" alt=""></div>
                                <div class="eye-icn-sec" @click="toggleShow('password')">
                                    <img loading="lazy" v-if="passwordFieldType=='text'" src="@/assets/images/eye-open.webp" alt="" >
                                    <img loading="lazy" v-else src="@/assets/images/eye-close.png" alt="">
                                </div>
                            </div>


                            <div v-if="showValidationError && form.password == ''"
                                class="thm-heading justify-content-start mt-2 password-wrong-con">
                                <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password.')}}</p>
                            </div>


                            <div class="check-box-sec form-container-sec">
                                <div class="box-left">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="check1" name="option1" value="something">
                                        <label class="form-check-label" for="check1">{{translatedLangData('keep-me-logged-in','Keep Me Logged In')}}</label>
                                    </div>
                                </div>
                                <div class="for-pass" v-if="siteSettings && siteSettings?.business_type == 2">
                                    <a data-bs-toggle="modal" href="#staticBackdrop-two">
                                        {{translatedLangData('forgot-password','Forgot Password?')}}
                                    </a>
                                </div>
                            </div>

                            <div class="login-option">
                                <div class="form-group">
                                <a @click="signInMethodCall()" class="btn btn-submit btn-login" :class="{ 'btn-loading': loading }">
                                    {{translatedLangData('login','Login')}} <span><b></b><b></b><b></b></span>
                                </a>
                            </div>
                            <div class="form-group demo-login">
                                <a @click="demoUserLogin()" class="btn btn-submit btn-login" :class="{ 'btn-loading': demoLoading }">
                                    {{translatedLangData('demo-login','Demo Login')}} <span><b></b><b></b><b></b></span>
                                </a>
                            </div>
                            </div>
                            <div v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link?.whatsappno1 && siteSettings?.business_type == 2"
                                class="whats-app">
                                <p class="create t-center">
                                    {{translatedLangData('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}
                                </p>
                                <div class="button-whatsapp">
                                    <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"
                                        type="button" class="btn-whatsapp"> <img loading="lazy" src="@/assets/images/whatsapp.webp" alt="">
                                        {{translatedLangData('whatsapp-now','Whatsapp Now')}} </a>
                                </div>
                            </div>
                            <div class="other-login-option" v-if="siteSettings && siteSettings?.business_type == 2">
                                 <h3>{{translatedLangData('or-login-with','Or Login With')}}</h3>
                                 <ul class="login-by-social-icon">
                                     
                                     <li>
                                        <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/facebook.webp" alt="">
                                        </a>
                                    </li>
                                     
                                     <li>
                                        <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/telegram.webp" alt="">
                                        </a>
                                    </li>
                                     
                                     <li>
                                        <a v-if="siteSettings?.social_link?.instagram_link" :href="siteSettings?.social_link?.instagram_link" target="_blank">
                                            <img loading="lazy" src="@/assets/images/instagram.webp" alt="">
                                        </a>
                                    </li>
                                     
                                     <!-- <li><a href="#"><img loading="lazy" src="@/assets/images/google-img.webp" alt=""></a></li> -->
                                 </ul>
                             </div>

                            
                            
                            <p class="whats-with-acc" v-if="siteSettings && siteSettings?.business_type == 2">{{translatedLangData('dont-have-an-account',"Don't Have An Account?")}} &nbsp;<a data-bs-toggle="modal"
                                    data-bs-target="#Register">{{translatedLangData('register','Register')}}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import { Modal } from 'bootstrap';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';

export default {
    name: "Login",
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            captchaData: null,
            passwordFieldType:'password',
        };
    },
    methods: {
        toggleShow(fieldName) {
            if(fieldName == 'password'){
                this.passwordFieldType = (this.passwordFieldType === 'text') ? 'password' : 'text';
            }
        },
        validateMobile(event){
            this.form.mobileNo = event.target.value.replace(/\D/g, '');
        },
        validateUserId(){
            const containsSpaces = /\s/.test(this.form.userId); 
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
        },
        validatePassword(){
            const containsSpaces = /\s/.test(this.form.password); 
            if (containsSpaces) {
                this.form.password = this.form.password.replace(/\s/g, '');
            }
        },
        demoUserLogin() {
            this.form.type = 1;
             this.form.userId="demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo')
        },
        refreshData(formtype) {
            this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type:formtype,
                captchaText: '',
                "cncode": this.form.code,
            },
                this.showValidationError = false
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        async loginServiceCall(url,type) {
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let id = this.getId();
            let data = {
                "cncode": this.form.code,
                "userid": id.toString(),
                "type": this.siteSettings?.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
            };
            api.post(url, data).then(response => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            localStorage.setItem('login_type', data.type)
                            this.$store.dispatch('setSiteVersion', null);
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.refreshData(data.type);
                            this.$router.go();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {

            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                asciiCodes: asciiCodes,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData?.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        }
    },
    components: { SuccessModal, ErrorModal },
    mounted() {
        if(localStorage.getItem('login_type') && this.siteSettings?.business_type == 2) {
            this.form.type = localStorage.getItem('login_type');
        }
    },
    unmounted() {
        document.body.classList.remove('login-body-bg', 'inner-body');
    },
    props: ['success-modal', 'error-modal']

};
</script>

<style scoped>

@keyframes stretch {
    0% {
        transform: scale(0.5);
        background-color: #B5DDF0;
    }

    50% {
        background-color: #CFE9F6;
    }

    100% {
        transform: scale(1);
        background-color: #FFFFFF;
    }
}

.btn.btn-loading {
    font-size: 0;
}

.btn.btn-loading span {
    display: flex;
    justify-content: center;
}

.btn.btn-loading span b {
    animation-direction: alternate;
    animation-duration: 0.5s;
    animation-fill-mode: none;
    animation-iteration-count: infinite;
    animation-name: stretch;
    animation-play-state: running;
    animation-timing-function: ease-out;
    border-radius: 100%;
    display: block;
    height: 10px;
    margin: 0 1px;
    width: 10px;
    animation-delay: 0.1s;
    margin: 0 5px;
}

.btn.btn-loading span b:first-child {
    animation-delay: 0s;
    margin: 0;
}

.btn.btn-loading span b:last-child {
    animation-delay: 0.2s;
    margin: 0;
}

.btn span {
    display: none;
}

.placed-bet-btn.btn-loading {
    padding: 10px;
}

</style>